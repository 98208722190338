import config from "../config";

export const API_BASE_URL = config.API_BASE_URL + "/api";
export const API_UPLOAD_FILE =
  API_BASE_URL + "/financial-spreading/process-file";
export const API_URL_STANDARD_ACCOUNTS = API_BASE_URL + "/standard-accounts";
export const API_LOGIN = API_BASE_URL + "/auth/jwt/login";
export const API_LOGOUT = API_BASE_URL + "/auth/jwt/logout";
export const API_FORGOT_PASSWORD = API_BASE_URL + "/forgot-password";
export const API_RESET_PASSWORD = API_BASE_URL + "/reset-password";
export const API_USER_ME = API_BASE_URL + "/users/me";

export const API_APPLICATIONS = API_BASE_URL + "/applications";
export const API_SAVE_APPLICATION = API_APPLICATIONS + "/:id/save";
export const API_CONFIRM_APPLICATION = API_APPLICATIONS + "/:id/confirm";
export const API_VALIDATE_APPLICATION = API_APPLICATIONS + "/:id/validate";
export const API_GET_APPLICATION_FILE = API_APPLICATIONS + "/:id/file";
export const API_GET_APPLICATION_TRANSLATED_FILE = API_GET_APPLICATION_FILE + "/translated";
export const API_APPLICATION_REPROCESS = API_APPLICATIONS + "/:id/reprocess";
export const API_PENDING_APPLICATIONS = API_APPLICATIONS + "/pending";
export const API_GET_APPLICATION_OCR = API_APPLICATIONS + "/:id/ocr"
export const API_GET_APPLICATION_SUMMARY = API_APPLICATIONS + "/summary";

export const API_GET_COMPANIES = API_BASE_URL + "/companies";
export const API_GET_COMPANY_SUMMARY = API_GET_COMPANIES + "/summary";
export const API_SEARCH_COMPANIES = API_GET_COMPANIES + "/search";
export const API_CONTACT_US = API_BASE_URL + "/contact-us";
export const API_CHANGE_PASSWORD = API_BASE_URL + "/change-password";

export const API_TEAMS = API_BASE_URL + "/teams";
export const API_TEAM_MEMBERS = API_BASE_URL + "/teams/members";

export const URL_DASHBOARD = "/";
export const URL_FORGOT_PASSWORD = "/forgot-password";
export const URL_RESET_PASSWORD = "/reset-password";
export const URL_LOGIN = "/login";
export const URL_FINANCIAL_SPREADING = "/financial-spreading";
export const URL_USER_PROFILE = "/profile";
export const URL_CHANGE_PASSWORD = "/change-password";
export const URL_CONTACT_US = "/contact-us";
export const URL_CONTACT_US_ERROR = "/error/contact-us";
export const URL_ERROR_STATUS = "/error/:status";
export const URL_APPLICATION = "/application";
export const URL_APPLICATION_CREATE = URL_APPLICATION + "/create";
export const URL_APPLICATION_VALIDATE = URL_APPLICATION + "/validate";
export const URL_APPLICATION_RESULT = URL_APPLICATION + "/result";
const URL_CLIENTS_SUMMARY = "/summary";
export const URL_CLIENTS_SUMMARY_ACTIVITY = URL_CLIENTS_SUMMARY + "/activities";
export const URL_CLIENTS_SUMMARY_PORTFOLIO = URL_CLIENTS_SUMMARY + "/portfolio";
export const URL_TEAM_ACTIVITY = "/team-activity";
export const URL_MANAGE_MEMBERS = "/members";
export const URL_USAGE_REPORT = "/usage-report";
export const URL_USERS = "/users";

export const URL_COMPANIES = "/companies";
export const URL_EDIT_COMPANIES = "/companies/:company_id/edit";
