// sample structure of ocr results
// {
//             "51": {
//                 "contents": [
//                     {
//                         "text": "Alphabet Inc.",
//                         "bbox": [
//                             274.6400146484375,
//                             742.3631553649902,
//                             337.3581848144531,
//                             752.3631553649902
//                         ]
//                     },
//                   },
// }

export default function processOcrResults(ocrResults) {
  return Object.keys(ocrResults).reduce((finalOcrData, page_number) => {
    const ocr_result = ocrResults[page_number]
    const { contents = [], widths, heights } = ocr_result

    contents.sort((a, b) => (heights - a.bbox[1]) - (heights - b.bbox[1]));
    const lines = [];
    const defaultNewLine = { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity, constructedStr: '' }
    
    let currentLine = { ...defaultNewLine, rowData: [] };
    let currentY = null;

    contents.forEach((contentRow) => {
      const { bbox } = contentRow
      const [left, reversedBottomY, right, reversedTopY] = bbox;
      const minY = (heights - reversedTopY) ;
      const maxY = (heights - reversedBottomY) ; 
      const midY = (minY + maxY) / 2

      if (currentY === null || Math.abs(midY - currentY) > 5) {
          if (currentLine.rowData.length > 0) {
              currentLine.rowData.sort((a, b) => a.bbox[0] - b.bbox[0])

              currentLine.constructedStr = currentLine.rowData.map(({ text }) => text).join('\n')

              const { maxX, maxY, minX, minY, rowData } = currentLine

              lines.push(currentLine); 

              const separatedLine = currentLine.constructedStr.split('\n').map((constructedStr) => ({
                constructedStr,
                maxX,
                maxY, 
                minX, 
                minY, 
                rowData,
              }))

              if (separatedLine.length > 1) {
                lines.push(...separatedLine); 
              }
          }
          currentLine = { ...defaultNewLine, rowData: [] }; 
          currentY = midY; 
      }

      currentLine.rowData.push(contentRow);

      currentLine.minX = Math.min(currentLine.minX, left / widths);
      currentLine.minY = Math.min(currentLine.minY, minY / heights);
      currentLine.maxX = Math.max(currentLine.maxX, right / widths);
      currentLine.maxY = Math.max(currentLine.maxY, maxY / heights);
    });

    currentLine.rowData.sort((a, b) => a.bbox[0] - b.bbox[0])

    currentLine.constructedStr = currentLine.rowData.map(({ text }) => text).join(' ')

    if (currentLine.rowData.length > 0) {
      lines.push(currentLine);
    }

    return {
      ...finalOcrData, 
      [parseInt(page_number, 10) + 1]: lines,
    }
  }, {})
}